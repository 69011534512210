@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .fadeIn {
    animation: fadeIn 0.5s ease-out both;
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  .fadeOut {
    animation: fadeOut 0.5s ease-out both;
  }

  #toastLoadingId{
    background: rgba(0,0,0,0.7);
    left: 10vw;
    top: 60px;
    bottom: 0px;
    margin: 0px;
    width: 150px;
    height: 50px;
    padding: 0px;
    min-height: 50px;
  }