
.leaflet-pane.leaflet-annotation-control-pane {
    position: absolute;
    background: transparent;

    width: 100vw;
    height: 100px;
    bottom: 0;
    right: 0;
    z-index: 100000;
    user-select: none;
    background: green;
    z-index: 100000;
}


.leaflet-control-container .leaflet-right.leaflet-top button,
.leaflet-control-container .leaflet-left.leaflet-top a,
a.leaflet-search-control-a.leaflet-search-icon {
    width: 40px !important;
    height: 40px !important;
    background-color: rgba(0, 0, 0, 0.8) !important;
    border: none !important;
    display: block;
}

.leaflet-top.leaflet-right {
    top: 90px;
    right: 16px;
    color: white !important;
}

.leaflet-control-zoom.leaflet-bar.leaflet-control {
    margin: 0;
    vertical-align: top;
}

#annotation-tool-tutorial-container {
    display: table-cell;
    position: fixed;
    top: 90px;
    right: 15px;
    width: 50px;
    height: 550px;
    background: transparent;
}

#basemap-tool-tutorial-container {
    display: table-cell;
    position: fixed;
    bottom: 12px;
    right: 68px;
    width: 48px;
    height: 48px;
    background: transparent;
}

#pinned-tool-tutorial-container {
    display: table-cell;
    position: fixed;
    bottom: 12px;
    right: 124px;
    width: 48px;
    height: 48px;
    background: transparent;
}

#location-tool-tutorial-container {
    display: table-cell;
    position: fixed;
    bottom: 2px;
    left: 0px;
    height: 100px;
    width: 330px;
    background: transparent;
    z-index: -1;
}

#box-zoom-button {
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2222%22%20height%3D%2222%22%20viewBox%3D%220%200%2022%2022%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Ccircle%20cx%3D%229.75004%22%20cy%3D%229.75001%22%20r%3D%224.91667%22%20stroke%3D%22white%22%2F%3E%0A%3Cpath%20d%3D%22M13.5%2013.5007L16.8334%2016.8337%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M1%207.66667V3C1%201.89543%201.89543%201%203%201H7.66667%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M1%2014.3333V19C1%2020.1046%201.89543%2021%203%2021H7.66667%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M21%207.66667V3C21%201.89543%2020.1046%201%2019%201H14.3333%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M21%2014.3333V19C21%2020.1046%2020.1046%2021%2019%2021H14.3333%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A') !important;
    background-repeat: no-repeat !important;
    background-size: 24px !important;
    background-position: 8px 8px !important;
    margin: 0 !important;
    padding: 0 !important;
}

a.leaflet-control-zoom-in {
    user-select: none;
    background-color: rgba(0, 0, 0, 0.8) !important;
    width: 40px !important;
    height: 40px !important;
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2221%22%20height%3D%2221%22%20viewBox%3D%220%200%2021%2021%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Ccircle%20cx%3D%229%22%20cy%3D%229%22%20r%3D%228.5%22%20stroke%3D%22white%22%2F%3E%0A%3Cpath%20d%3D%22M15%2015L20%2019.9998%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M9%205V13%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M13%209L5%209%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A') !important;
    background-repeat: no-repeat !important;
    background-size: 24px !important;
    background-position: 8px 8px !important;
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
    margin: 0 !important;
    padding: 0 !important;
    color: transparent !important;
}
a.leaflet-control-zoom-in span {
    color: transparent !important;
}

a.leaflet-control-zoom-out {
    user-select: none;
    background-color: rgba(0, 0, 0, 0.8) !important;
    width: 40px !important;
    height: 40px !important;
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2221%22%20height%3D%2221%22%20viewBox%3D%220%200%2021%2021%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Ccircle%20cx%3D%229%22%20cy%3D%229%22%20r%3D%228.5%22%20stroke%3D%22white%22%2F%3E%0A%3Cpath%20d%3D%22M15%2015L20%2019.9998%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M13%209L5%209%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A') !important;
    background-repeat: no-repeat !important;
    background-size: 24px !important;
    background-position: 8px 8px !important;
    border-radius: 0px;
    margin: 0 !important;
    padding: 0 !important;
    color: transparent !important;
    margin-bottom: -4px;
}