.react-autosuggest__container {
    position: relative;
    margin-left: 20px;
}

.react-autosuggest__input {
    width: 340px;
    height: 30px;
    padding: 7px 20px 10px 30px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

@media only screen and (max-width: 950px) {
    .react-autosuggest__input {
        width: 250px;
        font-size: 14px;
    }
    .react-autosuggest__container {
        margin-left: 10px;
    }
}

.react-autosuggest__input--focused {
    outline-color: #eed926;
    outline-width: 2px;
    outline-style: solid;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 340px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

@media only screen and (max-width: 600px) {
    .react-autosuggest__suggestions-container--open {
        width: 330px;
    }
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    z-index: 5000;
}

.react-autosuggest__suggestions-list li i {
    margin-right: 10px;
    color: #666;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 10px;
    word-break: break-all;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.react-autosuggest-search-icon {
    position: absolute;
    top: 8px;
    left: 10px;
    width: 24px;
    height: 24px;
    color: #666;
}

img.react-autosuggest-result-icon {
    width: 24px;
    margin-right: 8px;
}

.react-autosuggest-close-search {
    position: relative;
    top: 0px;
    right: 16px;
    width: 24px;
    height: 24px;
    color: #666;
    cursor: pointer;
}

.react-autosuggest-spinner {
    position: relative;
    color: #666;
    right: 20px;
}

@media only screen and (max-width: 600px) {
    .autocomplete-search-box {
        align-items: flex-end;
        z-index: 2000;
        width: calc(100vw - 32px);
        margin: 8px 16px 0px 16px;
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }
    .react-autosuggest-search-icon {
        color: #eed926;
        margin-top: 4px;
    }
    .react-autosuggest__input--focused {
        outline-color: #eed926;
        outline-width: 1px;
        outline-style: solid;
    }
    .react-autosuggest__container {
        margin: 0 auto;
        width: 100%;
    }
    .react-autosuggest__input {
        background: rgba(0, 0, 0, 0.7);
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 6px;
        width: 80vw;
        height: 39px;
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
        z-index: 10000;
        color: white;
    }
}