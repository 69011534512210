h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
tr,
td,
span,
strong,
div,
input {
    font-family: 'Exo 2', sans-serif !important;
    color: black;
}

body {
    background: black;
    overflow-x: hidden;
    font-size: 1rem;
}

/* This disables the page reload for mobile and tablet */
/* 1280px covers up to a 10.1 inch tablet screen */
@media only screen and (max-width: 1280px) {
    body {
        touch-action: none;
        overflow: hidden;
        overscroll-behavior-y: none !important;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    text-align: center;
}

html {
    font-size: 14px;
}

.center {
    text-align: center;
}

.toast-container {
    right: 3em;
    background: transparent;
    z-index: 100001 !important;
}

.Toastify__toast-container {
    margin-top: 70px;
}

.Toastify__toast-body {
    word-wrap: break-word;
    word-break: break-word;
}

@media only screen and (max-width: 600px) {
    .Toastify__toast-container {
        margin-top: 0px;
        z-index: 100001;
    }
}

/* Start toast custom notifications */
/* Dark toast custom notification */
.Toastify__toast.Toastify__toast--dark {
    background: linear-gradient(#121212 96%, #eed926 0);
}

.Toastify__toast.Toastify__toast--dark .Toastify__toast-body {
    color: white !important;
}

.Toastify__progress-bar--dark {
    background-color: rgb(18, 18, 18) !important;
}

@media only screen and (max-width: 500px) {
    .Toastify__toast-body {
        width: 100%;
    }
}

/* Error toast custom notification */

.Toastify__toast.Toastify__toast--error {
    background: linear-gradient(#121212 96%, rgb(231, 76, 60) 0);
}

.Toastify__toast.Toastify__toast--error .Toastify__toast-body {
    color: white !important;
}

.Toastify__progress-bar--error {
    background-color: rgb(18, 18, 18) !important;
}

/* Info toast custom notification */
.Toastify__toast.Toastify__toast--info {
    background: linear-gradient(#121212 96%, rgb(52, 152, 219) 0);
}

.Toastify__toast.Toastify__toast--info .Toastify__toast-body {
    color: white !important;
}

.Toastify__progress-bar--info {
    background-color: rgb(18, 18, 18) !important;
}

/* warning toast custom notification */
.Toastify__toast.Toastify__toast--warning {
    background: rgb(18, 18, 18) !important;
}

.Toastify__toast.Toastify__toast--warning .Toastify__toast-body {
    color: white !important;
}

.Toastify__progress-bar--warning {
    background-color: rgb(238, 217, 38) !important;
}

/* End toast custom notifications */

.Toastify__toast.Toastify__toast--info .Toastify__toast-body {
    color: #ffffff;
}

.Toastify__toast.Toastify__toast--info.add-listing-notification {
    background: transparent;
}

.Toastify__toast--info .Toastify__toast-body {
    color: white;
}

.Toastify__toast {
    box-shadow: none;
}

button.Toastify__close-button.Toastify__close-button--info {
    position: relative;
    top: 36px;
    right: 18px;
    color: #343a40;
    background: transparent;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: black;
    box-shadow: 0 0 0px 1000px transparent inset;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}

::-webkit-scrollbar,
::-ms-scrollbar {
    width: 12px !important;
}

::-webkit-scrollbar-track,
::-ms-scrollbar-track {
    background-color: #eed926;
}

::-webkit-scrollbar-thumb,
::-ms-scrollbar-thumb {
    background-color: #eed926;
    border-radius: 20px;
    border: 1px solid #eed926;
}

body {
    scrollbar-color: #eed926 rgba(0, 0, 0, 0.1);
}

.modal {
    z-index: 10000;
}

.modal-dialog {
    margin: auto;
    padding: 0px 5px;
}
